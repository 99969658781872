import { Box, Button, Card, Link, Typography } from '@mui/material';
import './App.css';
import { FaWhatsapp } from 'react-icons/fa6';

function App() {
  return (
    <div className="App">
      <Box component={'img'} src='/assets/background.png' sx={{
        display: {
          xs: 'none',
          md: 'block'
        },
        position: 'absolute',
        width: '100%', maxWidth: '100%', zIndex: -1
      }} />
      <Box component={'img'} src='/assets/background_mobile.png' sx={{
        display: {
          xs: 'block',
          md: 'none'
        },
        position: 'absolute', zIndex: -1, width: '100%'
      }} />
      <header className="App-header">
        <Card sx={{
          p: '2rem',
          m: '1rem',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant='h4' textAlign={'center'}>
            Website em desenvolvimento, aguarde.
          </Typography>
          <Button target='_blank' href='https://wa.me/5551995760457?text=Ol%C3%A1!%20Conheci%20a%20loja%20pelo%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es!' fullWidth variant='contained' color='success' startIcon={<FaWhatsapp />} sx={{
            mt: '2rem'
          }}>
            Entrar em contato pelo whatsapp
          </Button>
          <Typography color='grey' fontStyle={'italic'} mt={'1rem'} textAlign={'center'}>
            Projeto desenvolvido por <Link href="https://spitha.com.br/" target="_blank">Spítha - Soluções Digitais</Link>
          </Typography>
        </Card>
      </header>
    </div>
  );
}

export default App;
